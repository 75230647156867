import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/layout.js';
import styles from '../styles/menu.module.css';
import { graphql } from 'gatsby';

const Sushi = ({ data }) => {
  const sections = [
    { title: 'Draft House Special Rolls', subheader: 'Consumer Advisory* Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness.' },
    { title: 'Classic Sushi', subheader: '' },
    { title: 'Vegetable Rolls'},
    { title: 'Seaweed Outside Rolls', subheader: ''},
    { title: 'Nigiri-Sashimi (raw)', subheader: 'Add $1 for Sashimi'},
    { title: 'Seafood Bowls', subheader: '' },
    { title: 'Sushi and Sashimi Combo', subheader: 'Comes with soup or salad' }
    


  ];

  return (
    <Layout>
      <Helmet>
        <title>River City Drafthouse</title>
        <meta
          name='River City Drafthouse Sushi'
          content='River City Drafthouse Sushi Menu'
        />
      </Helmet>
      <div className={styles.container}>
        {sections.map(section => {
          return (
            <section className={styles.menuSection}>
              <header className={styles.sectionHeader}>
                <h1 className={styles.sectionTitle}>{section.title}</h1>
                <h3 className={styles.sectionSubheader}>{section.subheader}</h3>
              </header>
              {data.allMarkdownRemark.edges.map(element => {
                const {
                  title,
                  comboNumber,
                  price,
                  smallPrice,
                  description,
                  category
                } = element.node.frontmatter;
                if (category === section.title && section.title !== 'Combo') {
                  return (
                    <div className={styles.menuItem}>
                      <span className={styles.itemHeader}>
                        <h1 className={styles.itemName}>{title}</h1>
                        {/*smallPrice ? <p className={styles.price}>M: {smallPrice}</p> : ""*/}
                        {/*<p className={styles.price}>L: {price}</p>*/}
                        <p className={styles.price}>{price}</p>
                      </span>
                      {description ? (
                        <p className={styles.description}>{description}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                } else if (
                  category === section.title &&
                  section.title === 'Combo'
                ) {
                  return (
                    <div className={styles.menuItem}>
                      <span className={styles.itemHeader}>
                        <h1 className={styles.itemName}>
                          #{comboNumber} {title}
                        </h1>
                        {smallPrice ? (
                          <p className={styles.price}>L: {smallPrice}</p>
                        ) : (
                          ''
                        )}
                        <p className={styles.price}>D: {price}</p>
                      </span>
                      {description ? (
                        <p className={styles.description}>{description}</p>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                }
              })}
            </section>
          );
        })}
      </div>
      <p>
        <br></br>
      </p>
      <p>
        <br>
        </br>
      </p>
      <p>
        <br></br>
      </p>
    </Layout>
  );
};

export const query = graphql`
  query sushiQuery {
    allMarkdownRemark(sort: { fields: frontmatter___orderid, order: ASC }) {
      edges {
        node {
          frontmatter {
            title
            path
            category
            comboNumber
            price
            smallPrice
            description
            orderid
          }
        }
      }
    }
  }
`;
export default Sushi;
